<template>
  <v-flex xs12>
    <v-text-field label="Search"
                  solo
                  clearable
                  clear-icon="far fa-times-circle"
                  prepend-inner-icon="fas fa-search"
                  v-model="search"
                  @change="getSearchItems"
                  @input="getSearchItems"
                  @clear-icon-cb="getSearchItems"></v-text-field>
  </v-flex>
</template>

<script>
  export default {
    data() {
      return {
       search: ''
      }
    },
    methods: {
      getSearchItems: function () {
        this.$emit('gSearch', this.search)
      }
    }
  }
</script>

<style scope>
  .v-input__slot {
    border-radius: 95px !important;
  }
</style>
